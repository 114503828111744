a:hover{
  text-decoration: none !important;
}

.tiletut1 {
    width: 100%;
    border-left: 5px solid;
    border-left-color: #4a4a88;
    background: #5b5bb110;
    border-radius: 5px 5px 5px 5px;
    
  }
  
.tiletut2 {
  width: 100%;
  border-left: 5px solid;
  border-left-color: #80c000;
  background: #80c00012;
  border-radius: 5px 5px 5px 5px;

}
  
.tiletut3 {
  width: 100%;
  border-left: 5px solid;
  border-left-color: #ffab2d;
  background: #ffab2d10;
  border-radius: 5px 5px 5px 5px;
}

.tiletut1 div:hover {
  background: #5b5bb127;
  border-radius: 5px 5px 5px 5px;
}

.tiletut2 div:hover {
  background: #80c0002c;
  border-radius: 5px 5px 5px 5px;
}

.tiletut3 div:hover {
  background: #ffab2d38;
  border-radius: 5px 5px 5px 5px;
}

.tiletut1 h3{
  color: #4a4a88;
  padding-top: 4px; 
  padding-left: 10px;
  margin-bottom: 6px; 
  font-size: 22px;

}

.tiletut2 h3{
  color: #6da300;
  padding-top: 4px; 
  padding-left: 10px;
  padding-bottom: 4px;
  font-size: 22px;
}

.tiletut3 h3{
  color: #ffab2d;
  padding-top: 4px; 
  padding-left: 10px;
  padding-bottom: 0px;
  font-size: 22px;

}


.first_tutorial_p {
  font-size: 17px;
  padding-left: 12px;
  margin-top: 0px; 
  margin-bottom: 15px; 
}

.second_tutorial_p {
  font-size: 18px;
  float:right;
  padding-right: 8px;
  margin-top: 0px; 
}
