.home {
    width: 100%;
    display: grid;
    grid-column-gap: 35px;
    grid-template-columns: auto auto auto;
    
}
.SmallSize {
  display: block;
  width: 100%;
  
}

.homesecond {
  width: 100%;
  display: grid;
  grid-column-gap: 35px;
  grid-template-columns: 64% 32%;
  
}

.picture {
    float: left;
    padding-right: 25px; 
    padding-top: 10px;
  }

.top {
    padding-top: 25px;   
}

.icon{
    float: right;
    margin-top: -15px;
}

.title-separation-section {
    height: 4px;
    width: 60%;
    background: #333344;
    border-radius: 0 0 30px 30px;
    border-radius: 15px;
    margin-top: 35px;
    margin-bottom: 10px;
  }


  .about {
    clear: left;
    margin-top : 15px;
    border-color: #000000;
    background: #b6b6b610;
    border-radius: 5px 5px 5px 5px;
    height: fit-content;
  }

  .about div:hover {
    text-decoration: none !important;
    border-radius: 5px 5px 5px 5px;
    background: #b6b6b631;
    border-color: #b6b6b631;
  }


  .about h3{
    padding-top: 15px;
    padding-bottom: 5px;
    text-align: center;
    font-weight: 600;
    color: rgb(110, 110, 110);

  }

  .about p{
    color: rgb(83, 83, 83);
    padding-left: 20px;
    padding-right: 20px;

  }

  @media (min-width: 800px) {
    .SmallSize {
        display: none;
    }
}

@media (max-width: 801px) {
  .FullSize {
      display: none;
  }
}