.picture {
    float: left;
    padding-right: 25px; 
    padding-top: 10px;
  }

.top {
    padding-top: 25px;   
}

.icon{
    float: right;
    margin-top: -15px;
    margin-right: 20px;
    
}
.about a{
    text-decoration:#0055ff;
  }

.colorblue {
    color: #0056b3;

}



