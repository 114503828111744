.sidenav {
    height: 100%; /* Full-height: remove this if you want "auto" height */
    width: 200px; /* Set the width of the sidebar */
    max-width: 20%;
    min-width: 150px;
    position: fixed; /* Fixed Sidebar (stay in place on scroll) */
    z-index: 10; /* Stay on top */
    top: 0; /* Stay at the top */
    left: 0;
    background-color: #333344; /* Black */
    overflow-x: hidden; /* Disable horizontal scroll */
    padding-top: 20px;
  }
  
  /* The navigation menu links */
  .sidenav a {
    text-align: center;
    text-decoration: none;
    font-family: 'American Typewriter';
    font-weight: 400;
    font-size: 25px;
    color: #e4ffae;
    display: block;
  }
  
  .sidenav p {
  text-align: center;
  text-decoration: none;
  font-family: 'American Typewriter';
  font-weight: 400;
  font-size: 19px;
  color: #e7ffb6;
  display: block;
  position: absolute;
  bottom: 10px;
  left: 20px;
  padding: 5px;
  
  }
  
  /* When you mouse over the navigation links, change their color */
  .sidenav a:hover {
    text-decoration: none;
    color: #f6ffe3;
    font-weight: 600;
  }
  
  .active {
    color: #f6ffe3;
    font-weight: 600;
    text-shadow: 0 0 5px #cbff63,
              0 0 8px #cbff63, 0 0 10px #cbff63,
             0 0 20px #cbff63, 0 0 30px #cbff63,
             0 0 40px #cbff63;
  }

  .flex_column{
    display: flex;
  }

  .spacer {
    height: 25%;
  }

  @media (max-width: 800px) {
    .sidenav {
        display: none;
    }
}



