.tilebl1 {
    width: 90%;
    border-left: 4px solid;
    border-left-color: #4a4a88;
    background: #5b5bb110;
    border-radius: 5px 5px 5px 5px;
  }
  
  .tilebl2{
    width: 90%;
    border-left: 4px solid;
    border-left-color: #80c000;
    background: #80c00012;
    border-radius: 5px 5px 5px 5px;

  }
  
  .tilebl3{
    width: 90%;
    border-left: 4px solid;
    border-left-color: #ffab2d;
    background: #ffab2d10;
    border-radius: 5px 5px 5px 5px;

  }

  .tilebl1 h3{
    display: inline-block;
    color: #4a4a88;
    padding-top: 10px; 
    padding-left: 10px;
    padding-right: 0px;
    font-size: 23px;

  }

  .tilebl2 h3{
    display: inline-block;
    color: #6da300;
    padding-top: 10px; 
    padding-left: 10px;
    padding-right: 0px;
    font-size: 23px;

  }

  .tilebl3 h3{
    display: inline-block;
    color: #ffab2d;
    padding-top: 10px; 
    padding-left: 10px;
    padding-right: 0px;
    font-size: 23px;

  }

  .tilebl1 h4{
    color: #4a4a88;
    display: inline-block;
    padding-top: 7px; 
    padding-left: 10px;
    font-size: 20px;

  }

  .tilebl2 h4{
    color: #6da300;
    display: inline-block;
    padding-top: 7px; 
    padding-left: 10px;
    font-size: 20px;

  }

  .tilebl3 h4{
    color: #ffab2d;
    display: inline-block;
    padding-top: 7px; 
    padding-left: 10px;
    font-size: 20px;
  }

  .first_blog_p {
    padding-left: 12px;
    margin-top: 0px; 
    margin-bottom: 0px; 
  }

  .second_blog_p {
    float:right;
    padding-right: 20px;
    color: #000000;
    margin-top: 0px; 
  }

 p{
    padding-left: 12px;
    padding-right: 20px;
    color: #000000;
  }

  a {
    text-decoration: none;
    color: #000000;
  }
  .pic_blog{
    float: right;
    padding-right: 18px; 
    padding-top: 10px;
    color:  #000000;
  }
  .blog-separation1{
    height: 1px;
    width: 60%;
    background: #4a4a88;
    margin-left: auto;
    margin-right: auto;  
    border-radius: 0 0 30px 30px;
    border-radius: 15px;
    margin-top: 6px;
    margin-bottom: 10px;
  }  

  .blog-separation2{
    height: 1px;
    width: 60%;
    background: #6da300;
    margin-left: auto;
    margin-right: auto;  
    border-radius: 0 0 30px 30px;
    border-radius: 15px;
    margin-top: 6px;
    margin-bottom: 10px;
  } 

  .first {
    padding-left: 5px;

  }

  .second {
    float:right;
    padding-right: 8px;
    color: #000000;
  }

