
html, body {
  overflow-x: hidden;
}
.App {
  text-align: left;
  font-family: 'American Typewriter', Courier, 'Times New Roman';
  padding-top: 20px;
}

.App h1 {
  text-transform: uppercase;
  letter-spacing: 0.5px;
  color: #333344; /* Black */
  overflow-x: hidden; /* Disable horizontal scroll */
  font-weight: 600;
  font-size: 60px;
  display: block;
  text-align: left;
}

.App h2{
  text-transform: capitalize;
  letter-spacing: 0.3px;
  color: #333344; /* Black */
  overflow-x: hidden; /* Disable horizontal scroll */
  font-weight: 600;
  font-size: 40px;
  display: block;
  text-align: left;
  font-style: italic;
}

.App p{
  text-align: justify;
}

.PageContainer {
  padding-left: 5px;

}

.coloured {
  background-color: #9393c7;
  background-size: cover;

}

.title-separation-long {
    height: 4px;
    width: 100%;
    background: #333344;
    border-radius: 0 0 30px 30px;
    border-radius: 15px;
    margin-bottom: 25px;
}

.title-separation {
  height: 4px;
  width: 60%;
  background: #333344;
  border-radius: 0 0 30px 30px;
  border-radius: 15px;
  margin-bottom: 25px;
}


  
