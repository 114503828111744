.tilead1 {
    width: 100%;
    border-left: 4px solid;
    border-left-color: #4a4a88;
    background: #5b5bb110;
    border-radius: 5px 5px 5px 5px;
    
  }
  
  .tilead2{
    width: 100%;
    border-left: 4px solid;
    border-left-color: #80c000;
    background: #80c00012;
    border-radius: 5px 5px 5px 5px;

  }
  
  .tilead3{
    width: 100%;
    border-left: 4px solid;
    border-left-color: #ffab2d;
    background: #ffab2d10;
    border-radius: 5px 5px 5px 5px;
  }

  .tilead1 h3{
    color: #4a4a88;
    padding-top: 4px; 
    padding-left: 10px;
    margin-bottom: 0px; 
    font-size: 18px;

  }

  .tilead2 h3{
    color: #6da300;
    margin-bottom: 0px; 
    padding-top: 4px; 
    padding-left: 10px;
    padding-bottom: 8px;;
    font-size: 18px;

  }

  .tilead3 h3{
    color: #ffab2d;
    padding-top: 4px; 
    padding-left: 10px;
    font-size: 16px;

  }

  .tilead1 div:hover {
    background: #5b5bb12f;
    border-color: #4a4a88;
    border-radius: 5px 5px 5px 5px;
  }


  .tilead2 div:hover {
    background: #80c00048;
    border-color: #80c000;
    border-radius: 5px 5px 5px 5px;
  }

  .first_add_p {
    padding-left: 12px;
    margin-top: 0px; 
    margin-bottom: 0px; 
  }

  .second_add_p {
    float:right;
    padding-right: 8px;
    color: #000000;
    margin-top: -15px; 
  }

 p{
    padding-left: 12px;
    color: #000000;
    padding-bottom: 5px;
  }

  a {
    text-decoration: none;
    color: #000000;
  }
  .icon_add{
    float: right;
    padding-right: 20px; 
    padding-top: 10px;
    color:  #000000;
  }
  

