.tile1 {
    width: 85%;
    border-left: 4px solid;
    border-left-color: #4a4a88;
    background: #5b5bb110;
    
  }
  
  .tile2{
    width: 85%;
    border-left: 4px solid;
    border-left-color: #80c000;
    background: #80c00012;

  }
  
  .tile3{
    width: 85%;
    border-left: 4px solid;
    border-left-color: #ffab2d;
    background: #ffab2d10;

  }

  .tile1 h3{
    color: #4a4a88;
    padding-top: 4px; 
    padding-left: 10px;
    padding-bottom: 5px;
    font-size: 18px;

  }

  .tile2 h3{
    color: #80c000;
    padding-top: 4px; 
    padding-left: 10px;
    font-size: 18px;

  }

  .tile3 h3{
    color: #ffab2d;
    padding-top: 4px; 
    padding-left: 10px;
    font-size: 18px;

  }

  .firstp {
    padding-left: 12px;
    padding-right: 25px;
    margin-top: 0px; 
    margin-bottom: 0px; 
  }

  .secondp {
    float:right;
    margin-top: 5px; 
    padding-right: 8px;
    color: #000000;
  }

 p{
    padding-left: 12px;
    color: #000000;
  }

  .pic a{
    text-decoration: none;
    padding-top: 5px;
    padding-left: 8px; 
    padding-right: -5px; 
    color: #000000;
  }
  .pic {
    float: right;
    color:  #000000;
  }
  

