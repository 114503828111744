.tile1top {
  width: 100%;
  height: 45px;
  float: left;
  background: #4a4a88 !important; 

    border-radius: 5px 5px 0 0;

  }
  
  .tile2top{
    width: 100%;
    height: 45px;
    float: left;
    background: #80c000 !important; 
    border-radius: 5px 5px 0 0;

  }
  
  .tile3top{
    width: 100%;
    height: 50px;
    float: left;
    background: #f1a129 !important;
    border-radius: 20px 20px 0 0;

  }

  .tile1bottom {
    width: 100%;
    border-left: 5px solid;
    margin-bottom: 10px;
    float: left;
    border-color: #4a4a88 !important;
    background: #5b5bb110;
    border-radius: 0 0 5px 5px;
    box-sizing: border-box;
    
  }
  
  .tile2bottom{
    width: 100%;
    border-left: 5px solid;
    margin-bottom: 10px;
    float: left;
    border-color: #80c000;
    background: #80c00012;
    border-radius: 0 0 5px 5px;
    box-sizing: border-box;

  }
  
  .tile3bottom{
    width: 100%;
    border: 3px solid;
    margin-bottom: 10px;
    float: left;
    border-color: #f1a129;
    background: #ffab2d10;
    border-radius: 0 0 20px 20px;
    box-sizing: border-box;
  }
  
  .parent1 {
    position: relative;

  }

  .parent1 div:hover {
    background: #5b5bb12f;
    border-color: #4a4a88;
  }

  .parent2 {
    position: relative;

  }

  .parent2 div:hover {
    background: #80c00048;
    border-color: #80c000;
  }


  .parent3 {
    position: relative;

  }

  .parent3 div:hover {
    background: #ffab2d38;
    border-color: #f1a129;
  }

  .tile1top h2{
    color: #ffffff;
    text-align: center;
    font-size: 30px;
    padding-top: 5px;
    font-style: normal;  
    
  }

  .tile2top h2{
    color: #ffffff;
    text-align: center;
    font-size: 30px;
    padding-top: 5px;
    font-style: normal;  
    
  }

  .parent1 p{
    padding: 10px 10px 10px 10px;
    font-size: 18px;

  }

  .parent2 p{
    padding: 10px 10px 10px 10px;
    font-size: 18px;

  }

  .parent3 p{
    padding: 10px 10px 10px 10px;
    font-size: 18px;

  }