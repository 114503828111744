.title-separation-long {
    height: 4px;
    width: 100%;
    background: #333344;
    border-radius: 0 0 30px 30px;
    border-radius: 15px;
    margin-bottom: 22px;
    overflow:visible;
}

.menu-separator {
    height: 20px;
    width: 3px;
    background: #333344;
    float:right;
    margin-top: 2.5px;
}

.subnav {
    height: 70px; /* Full-height: remove this if you want "auto" height */
    width: 100%; /* Set the width of the sidebar */
    min-width: 40%;
    margin-top: -15px;
    position: relative;
  }
  
  /* The navigation menu links */
  .subnav a {
    text-align: center;
    text-decoration: none;
    font-family: 'American Typewriter';
    font-size: 18px;
    color: #333344;
    display: inline-block;
    margin-bottom: 3px;
    margin-right: 15px;
    margin-left: 15px;
    
  }
  
  /* When you mouse over the navigation links, change their color */
  .subnav a:hover {
    text-shadow: 0 0 5px #9e9eff, 0 0 10px #9e9eff, 0 0 15px #9e9eff;
    color: #333344;
    text-decoration: none;
  }

  .subnav div{
    display:inline-block;
  }
  
  .highlight {
    font-weight: 620;
    letter-spacing: -0.5px;
  }
