.tiletut1 {
    position: relative;
  
    }
.tiletut2 {
    position: relative;

}
.tiletut3 {
    
    }

.tiletutl1 .head{
    width: 100%;
    border-left: 5px solid;
    border-left-color: #4a4a88;
    background: #5b5bb127;
    border-radius: 5px 5px 0 0;
  
    }
    
.tiletutl2 .head{
    width: 100%;
    border-left: 5px solid;
    border-left-color: #80c000;
    background: #80c0002c;
    border-radius: 5px 5px 0 0;

}
    
.tiletutl3 .head{
    width: 100%;
    border-left: 5px solid;
    border-left-color: #ffab2d;
    background: #ffab2d38;
    border-radius: 5px 5px 0 0;

}
  
.tiletutl1 .body {
    width: 100%;
    border-left: 5px solid;
    border-right: 3px solid;
    border-bottom: 3px solid;
    border-left-color: #4a4a88;
    border-right-color:#5b5bb127;
    border-bottom-color:#5b5bb127;
    border-radius: 0 0 5px 5px;
    box-sizing: border-box;
    
}
    
.tiletutl2 .body{
    width: 100%;
    border-left: 5px solid;
    border-right: 3px solid;
    border-bottom: 3px solid;
    border-left-color: #80c000;
    border-right-color:#80c0002c;
    border-bottom-color:#80c0002c;
    border-radius: 0 0 5px 5px;
    box-sizing: border-box;
}
    
.tiletutl3 .body{
    width: 100%;
    border-left: 5px solid;
    border-right: 3px solid;
    border-bottom: 3px solid;
    border-left-color: #f1a129;
    border-right-color:#ffab2d38;
    border-bottom-color:#ffab2d38;
    border-radius: 0 0 5px 5px;
    box-sizing: border-box;
}

.first_tutoriall_p {
    font-size: 17px;
    padding-left: 12px;
    margin-top: 0px; 
    margin-bottom: 0px; 
  }
  
.second_tutoriall_p {
    font-size: 18px;
    float:right;
    padding-right: 8px;
    margin-top: 0px; 
    }

    .tiletutl1 h3{
        color: #4a4a88;
        padding-top: 4px; 
        padding-left: 10px;
        margin-bottom: 4px; 
        font-size: 22px;
      
      }
      
      .tiletutl2 h3{
        color: #6da300;
        padding-top: 4px; 
        padding-left: 10px;
        padding-bottom: 0px;
        font-size: 22px;
      }
      
      .tiletutl3 h3{
        color: #ffab2d;
        padding-top: 4px; 
        padding-left: 10px;
        padding-bottom: 4px;
        font-size: 22px;
      
      }

    .tryright {
        float: right;
        padding-right: 8px;
        padding-top: 2px;
    }

    .tryright :hover{
    color: grey !important;
    }


  