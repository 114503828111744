

.SideDrawer {
    height: 100%; /* Full-height: remove this if you want "auto" height */
    width: 50px;
    max-width: 10%;
    min-width: 50px;
    position: fixed; /* Fixed Sidebar (stay in place on scroll) */
    z-index: 1; /* Stay on top */
    top: 0; /* Stay at the top */
    left: 0;
    background-color: #333344; /* Black */
    overflow-x: hidden; /* Disable horizontal scroll */
    padding-top: 20px;
  }
  
  /* The navigation menu links */
  .SideDrawer a {
    text-align: center;
    text-decoration: none;
    font-family: 'American Typewriter';
    font-weight: 400;
    font-size: 20px;
    color: #e4ffae;
    display: block;
  }
  
  .SideDrawer p {
  text-align: center;
  text-decoration: none;
  font-family: 'American Typewriter';
  font-weight: 400;
  font-size: 19px;
  color: #e7ffb6;
  display: block;
  position: absolute;
  bottom: 10px;
  left: 20px;
  padding: 5px;
  
  }
  
  /* When you mouse over the navigation links, change their color */
  .SideDrawer a:hover {
    text-decoration: none;
    color: #f6ffe3;
    font-weight: 600;
  }
  
  .active {
    color: #f6ffe3;
  }

  .flex_column{
    display: flex;
  }

  .spacer {
    height: 4%;
  }

  .background{
      background: #333344;
      float: left;
      border-radius: 5px 5px 5px 5px;
  }

@media (min-width: 800px) {
    .SideDrawer {
        display: none;
    }
    .background{
        display: none;
    }
}

.Open {
    transform: translateX(0);

}

.Close {
    transform: translateX(-100);

}